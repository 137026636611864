import request from "graphql-request";
import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import { urls } from "@shared/constants";
import { Button, PicterLogo, Text } from "@shared/elements";
import { docTitle } from "@shared/utils";


import { Error404CallPage, TError404CallPage } from "__generated__/types";

import { MESSAGE_PREFIX } from "src/constants";
import bundleMessages from "src/utils/bundleMessages";

export default function Error404() {
  const t = useTranslations(`${MESSAGE_PREFIX}pages.404`);
  const { asPath } = useRouter();
  const [callPage, setCallPage] = useState<
    TError404CallPage["callPage"] | null
  >(null);
  const { show: showIntercom } = useIntercom();
  const [, slug] = asPath.split("/");

  useEffect(() => {
    async function getCallPage() {
      const data: TError404CallPage | null = await request(
        `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
        Error404CallPage,
        { slug },
      );

      setCallPage(data?.callPage);
    }

    getCallPage();
  }, [slug]);

  return (
    <>
      <Head>
        <title key="title">{docTitle(t("documentTitle"))}</title>
      </Head>
      <div className="w-full h-screen px-4 flex flex-col items-center justify-between">
        <div className="h-full flex flex-col items-center justify-center">
          <PicterLogo className="mb-14" logoClassName="hidden" />
          <Text as="p" weight="semibold" color="primary" className="mb-3">
            {t("title")}
          </Text>
          <Text as="h1" size="3xl" weight="bold" className="mb-3">
            {t("description")}
          </Text>
          <Text as="p" className="mb-5 text-center">
            {t("message")}
          </Text>
          {callPage?.slug ? (
            <Button
              as={Link}
              href={callPage.slug}
              variant="primary"
              className="w-full md:w-auto"
            >
              {t("backHomeBtn")}
            </Button>
          ) : (
            <Button
              as={Link}
              variant="primary"
              className="w-full md:w-auto"
              href={urls.index}
            >
              {t("backHomeBtn")}
            </Button>
          )}
        </div>
        <Button variant="text" onClick={showIntercom} className="text-gray-500">
          {t("contactSupport")}
        </Button>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, [`${MESSAGE_PREFIX}pages.404`]);

  return {
    props: {
      messages,
    },
    revalidate: 60,
  };
}
